<template>
  <div>
    <section id="content">
      <div class="section nobg nomargin">
        <div class="container center">
          <div class="heading-block center">
            <h2>About M-Changa</h2>
          </div>
          <p class="lead-paragraph">
            M-Changa is an online & mobile fundraising platform that provides
            individuals and institutions with a simple, secure and convenient
            way to raise funds virtually.
          </p>
        </div>
      </div>
      <div class="row champions-description">
        <div class="col-md-4" v-for="n in aboutDetail" :key="n">
          <div class="champion-desc-box nobg">
            <img class="mx-auto" :src="n.src" />
            <h3>
              {{ n.text }}
            </h3>
          </div>
        </div>
      </div>
      <div class="section nobg nomargin">
        <div class="container center">
          <div class="heading-block center">
            <h2>Simple, Secure, and Convenient Fundraising</h2>
          </div>
          <p>
            Register, verify and manage a fundraiser on M-Changa from the
            comfort of your device. We help our fundraisers enjoy the benefit of
            transparency, diverse mobile money donations and electronic
            donations options from all over the globe, easy withdrawal and
            reporting.
          </p>
        </div>
        <div class="container center">
          <div class="flex justify-center">
            <router-link to="/register" class="button mx-auto w-44"
              >Start Today</router-link
            >
          </div>
        </div>
      </div>
      <div class="section nobg nomargin">
        <div class="container center">
          <div class="heading-block center">
            <h2>Would you like to become an M-Changa Africa Affiliate?</h2>
          </div>
          <p>
            You can earn from referring M-Changa to your networks! Please
            contact customer support at support@mchanga.africa or +254 20 765
            0919 to learn about the M-Changa Africa affiliate program.
          </p>
        </div>
      </div>
      <div class="section nomargin nopadding">
        <div class="container">
          <div class="heading-block center">
            <h2>Why Choose M-Changa</h2>
          </div>

          <div class="champions-faq">
            <dl class="space-y-8 toggle">
              <Disclosure as="div" v-slot="{ open }">
                <dt class="pb-3 togglet">
                  <DisclosureButton
                    class="text-left w-full flex justify-between items-center text-gray-400"
                  >
                    <div class="title-blocks">
                      <img
                        src="https://res.cloudinary.com/changa/image/upload/f_auto,q_auto/v1643117694/web/ic-why-choose-1.png"
                      />
                      <h3>Best Value</h3>
                    </div>
                    <span class="h-7 flex items-center">
                      <ChevronDownIcon
                        :class="[
                          open
                            ? '-rotate-90 text-main'
                            : 'rotate-0 text-secondary',
                          'h-5 md:h-6 w-5 md:w-6 transform',
                        ]"
                        aria-hidden="true"
                      />
                    </span>
                  </DisclosureButton>
                </dt>
                <transition name="fade">
                  <DisclosurePanel
                    as="dd"
                    class="md:pr-12 transform transition-all"
                  >
                    <div class="togglec">
                      <p>
                        Fundraisers pay a
                        <a :href="this.baseURL + '/fees'" title="M-Changa Fees">
                          base fee of 4.25%
                        </a>
                        of funds raised. This allows us to keep the platform
                        free for your donors, as well as to sustain & improve
                        the platform to help fundraisers raise more, faster.
                      </p>
                    </div>
                  </DisclosurePanel>
                </transition>
              </Disclosure>
            </dl>
            <dl class="space-y-8 toggle">
              <Disclosure as="div" v-slot="{ open }">
                <dt class="pb-3 togglet">
                  <DisclosureButton
                    class="text-left w-full flex justify-between items-center text-gray-400"
                  >
                    <div class="title-blocks">
                      <img
                        src="https://res.cloudinary.com/changa/image/upload/f_auto,q_auto/v1643117694/web/ic-why-choose-2.png"
                      />
                      <h3>Mobile & Card Payments</h3>
                    </div>
                    <span class="h-7 flex items-center">
                      <ChevronDownIcon
                        :class="[
                          open
                            ? '-rotate-90 text-main'
                            : 'rotate-0 text-secondary',
                          'h-5 md:h-6 w-5 md:w-6 transform',
                        ]"
                        aria-hidden="true"
                      />
                    </span>
                  </DisclosureButton>
                </dt>
                <transition name="fade">
                  <DisclosurePanel
                    as="dd"
                    class="md:pr-12 transform transition-all"
                  >
                    <div class="togglec">
                      <p>
                        Every fundraiser can easily collect donations through
                        multiple payment channels including Mobile Money:
                        Safaricom Paybill, Equitel, Airtel, T-Kash (KE), Tigo,
                        Airtel MTN (GH), Airtel, MTN (UG) Visa, MasterCard &
                        PayPal (Worldwide).
                      </p>
                    </div>
                  </DisclosurePanel>
                </transition>
              </Disclosure>
            </dl>
            <dl class="space-y-8 toggle">
              <Disclosure as="div" v-slot="{ open }">
                <dt class="pb-3 togglet">
                  <DisclosureButton
                    class="text-left w-full flex justify-between items-center text-gray-400"
                  >
                    <div class="title-blocks">
                      <img
                        src="https://res.cloudinary.com/changa/image/upload/f_auto,q_auto/v1643117694/web/ic-why-choose-3.png"
                      />
                      <h3>Create Awareness</h3>
                    </div>
                    <span class="h-7 flex items-center">
                      <ChevronDownIcon
                        :class="[
                          open
                            ? '-rotate-90 text-main'
                            : 'rotate-0 text-secondary',
                          'h-5 md:h-6 w-5 md:w-6 transform',
                        ]"
                        aria-hidden="true"
                      />
                    </span>
                  </DisclosureButton>
                </dt>
                <transition name="fade">
                  <DisclosurePanel
                    as="dd"
                    class="md:pr-12 transform transition-all"
                  >
                    <div class="togglec">
                      <p>
                        Fundraiser Sharepages are visible online for anyone in
                        the world to see. Sharepages can be personalised with
                        stories, pictures, videos & targets. M-Changa makes it
                        easy to promote fundraisers across multiple channels
                        including Facebook, Twitter & WhatsApp.
                      </p>
                    </div>
                  </DisclosurePanel>
                </transition>
              </Disclosure>
            </dl>
            <dl class="space-y-8 toggle">
              <Disclosure as="div" v-slot="{ open }">
                <dt class="pb-3 togglet">
                  <DisclosureButton
                    class="text-left w-full flex justify-between items-center text-gray-400"
                  >
                    <div class="title-blocks">
                      <img
                        src="https://res.cloudinary.com/changa/image/upload/f_auto,q_auto/v1643117694/web/ic-mfund-feat-6.png"
                      />
                      <h3>Security & Trust</h3>
                    </div>
                    <span class="h-7 flex items-center">
                      <ChevronDownIcon
                        :class="[
                          open
                            ? '-rotate-90 text-main'
                            : 'rotate-0 text-secondary',
                          'h-5 md:h-6 w-5 md:w-6 transform',
                        ]"
                        aria-hidden="true"
                      />
                    </span>
                  </DisclosureButton>
                </dt>
                <transition name="fade">
                  <DisclosurePanel
                    as="dd"
                    class="md:pr-12 transform transition-all"
                  >
                    <div class="togglec">
                      <p>
                        We combine technology, human expertise and community to
                        minimise the risk of funds being misused. Security
                        features include treasurers, fundraiser verification and
                        secure banking & reporting systems.
                      </p>
                    </div>
                  </DisclosurePanel>
                </transition>
              </Disclosure>
            </dl>
            <dl class="space-y-8 toggle">
              <Disclosure as="div" v-slot="{ open }">
                <dt class="pb-3 togglet">
                  <DisclosureButton
                    class="text-left w-full flex justify-between items-center text-gray-400"
                  >
                    <div class="title-blocks">
                      <img
                        src="https://res.cloudinary.com/changa/image/upload/f_auto,q_auto/v1643117694/web/ic-why-choose-5.png"
                      />
                      <h3>Transparency</h3>
                    </div>
                    <span class="h-7 flex items-center">
                      <ChevronDownIcon
                        :class="[
                          open
                            ? '-rotate-90 text-main'
                            : 'rotate-0 text-secondary',
                          'h-5 md:h-6 w-5 md:w-6 transform',
                        ]"
                        aria-hidden="true"
                      />
                    </span>
                  </DisclosureButton>
                </dt>
                <transition name="fade">
                  <DisclosurePanel
                    as="dd"
                    class="md:pr-12 transform transition-all"
                  >
                    <div class="togglec">
                      <p>
                        Transparency is an important aspect of fundraising since
                        it inspires trust. M-Changa makes it easy for donors to
                        see fundraising goals and updates.
                      </p>
                    </div>
                  </DisclosurePanel>
                </transition>
              </Disclosure>
            </dl>
            <dl class="space-y-8 toggle">
              <Disclosure as="div" v-slot="{ open }">
                <dt class="pb-3 togglet">
                  <DisclosureButton
                    class="text-left w-full flex justify-between items-center text-gray-400"
                  >
                    <div class="title-blocks">
                      <img
                        src="https://res.cloudinary.com/changa/image/upload/f_auto,q_auto/v1643117694/web/ic-why-choose-6.png"
                      />
                      <h3>Automatic Fundraiser Acceleration</h3>
                    </div>
                    <span class="h-7 flex items-center">
                      <ChevronDownIcon
                        :class="[
                          open
                            ? '-rotate-90 text-main'
                            : 'rotate-0 text-secondary',
                          'h-5 md:h-6 w-5 md:w-6 transform',
                        ]"
                        aria-hidden="true"
                      />
                    </span>
                  </DisclosureButton>
                </dt>
                <transition name="fade">
                  <DisclosurePanel
                    as="dd"
                    class="md:pr-12 transform transition-all"
                  >
                    <div class="togglec">
                      <p>
                        Let the technology assist you! M-Changa automatically
                        invites your supporters to share and promote your
                        fundraiser by sending thank you messages that include
                        your fundraisers share link.
                      </p>
                    </div>
                  </DisclosurePanel>
                </transition>
              </Disclosure>
            </dl>
            <dl class="space-y-8 toggle">
              <Disclosure as="div" v-slot="{ open }">
                <dt class="pb-3 togglet">
                  <DisclosureButton
                    class="text-left w-full flex justify-between items-center text-gray-400"
                  >
                    <div class="title-blocks">
                      <img
                        src="https://res.cloudinary.com/changa/image/upload/f_auto,q_auto/v1643117694/web/ic-mfund-feat-7.png"
                      />
                      <h3>Local Customer support</h3>
                    </div>
                    <span class="h-7 flex items-center">
                      <ChevronDownIcon
                        :class="[
                          open
                            ? '-rotate-90 text-main'
                            : 'rotate-0 text-secondary',
                          'h-5 md:h-6 w-5 md:w-6 transform',
                        ]"
                        aria-hidden="true"
                      />
                    </span>
                  </DisclosureButton>
                </dt>
                <transition name="fade">
                  <DisclosurePanel
                    as="dd"
                    class="md:pr-12 transform transition-all"
                  >
                    <div class="togglec">
                      <p>
                        Live Customer Care 6 days a week through email, phone &
                        social media.
                      </p>
                    </div>
                  </DisclosurePanel>
                </transition>
              </Disclosure>
            </dl>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/vue';
import { ChevronDownIcon } from '@heroicons/vue/outline';
export default {
  components: {
    Disclosure,
    DisclosureButton,
    DisclosurePanel,
    ChevronDownIcon,
  },
  data() {
    return {
      aboutDetail: [
        {
          src: 'https://res.cloudinary.com/changa/image/upload/f_auto,q_auto/v1643117694/web/ic-about-1.png',
          text: 'Quickstart fundraising',
        },
        {
          src: 'https://res.cloudinary.com/changa/image/upload/f_auto,q_auto/v1643117694/web/ic-about-2.png',
          text: ' fundraisers activated',
        },
        {
          src: 'https://res.cloudinary.com/changa/image/upload/f_auto,q_auto/v1643117694/web/ic-about-3.png',
          text: '1 million plus supporters',
        },
      ],
      numberFundraisers: 0,
      numberDonations: 0,
      changaStats: {},
    };
  },

  async created() {
    this.homeData();
  },
  methods: {
    homeData: async function () {
      fetch(process.env.VUE_APP_ROOT_API + `/service/fundraiser/home-stats`)
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          this.changaStats = data;
          this.numberFundraisers = data.all_accounts.toLocaleString('en', {
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
          });
          this.numberDonations = data.all_donations.toLocaleString('en', {
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
          });
          this.aboutDetail[1].text =
            this.numberFundraisers + ' fundraisers activated';
          this.aboutDetail[2].text = this.numberDonations + ' plus supporters';
        });
      fetch(process.env.VUE_APP_ROOT_API + `/service/fundraiser/trending`)
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          this.trends = data;
        });
    },
  },
};
</script>
