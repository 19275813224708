<template>
  <Head>
    <title>About Us | M-Changa Africa</title>
    <meta
      name="description"
      content="M-Changa Africa: A fundraising platform that provides individuals and institutions with a simple, secure and convenient way to raise funds virtually."
    />
    <meta name="author" content="Mobi Changa" />
    <meta property="og:type" content="website" />
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    <meta property="og:url" :content="this.baseURL + '/mobile-fundraiser'" />
    <meta property="og:title" content="About Us | M-Changa Africa" />
    <meta
      property="og:description"
      content="M-Changa Africa: A fundraising platform that provides individuals and institutions with a simple, secure and convenient way to raise funds virtually."
    />
    <meta property="fb:app_id" :content="this.facebookID" />
  </Head>
  <div>
    <Breadcrumbs
      src="https://res.cloudinary.com/changa/image/upload/f_auto,q_auto/v1643117694/web/top-banner-about.jpg"
      breadcrumb="About"
      title="About Us"
    />
    <section id="content">
      <div class="content-wrap py-0">
        <div class="section nobg nomargin nopadding">
          <div class="tabs justified-tabs about-sub-nav tabs-blog clearfix">
            <div class="container">
              <ul class="tab-nav clearfix">
                <li
                  class="cursor-pointer px-3"
                  v-on:click="toggleTabs(1)"
                  v-bind:class="{
                    'bg-transparent': openTab !== 1,
                    'bg-white': openTab === 1,
                  }"
                >
                  About M-Changa
                </li>
                <li
                  class="cursor-pointer px-3"
                  v-on:click="toggleTabs(2)"
                  v-bind:class="{
                    'bg-main': openTab !== 2,
                    'bg-white': openTab === 2,
                  }"
                >
                  Meet Our Team
                </li>
              </ul>
            </div>
            <div class="tab-container">
              <div class="tab-content clearfix" id="tab-about-1">
                <div class="container">
                  <div class="row">
                    <div class="article-items-list-wrap">
                      <div
                        v-bind:class="{
                          hidden: openTab !== 1,
                          block: openTab === 1,
                        }"
                      >
                        <AboutUsCard />
                      </div>
                      <div
                        v-bind:class="{
                          hidden: openTab !== 2,
                          block: openTab === 2,
                        }"
                      >
                        <TeamCard />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <QuestionBottom />
    </section>
    <Footer />
  </div>
</template>
<script>
import { Head } from "@vueuse/head";
import Breadcrumbs from "../components/Breadcrumbs.vue";
import AboutUsCard from "../components/AboutUsCard.vue";
import  {lazyLoadComponent}  from "../utils/lazy-load-components";
import loader from '../components/loader.vue';

export default {
  components: {
    Head,
    Breadcrumbs,
    AboutUsCard,
    TeamCard: lazyLoadComponent({
      componentLoader: () => import('../components/TeamCard.vue'),
      loading: loader,
    }),
    QuestionBottom: lazyLoadComponent({
      componentLoader: () => import('../components/QuestionBottom.vue'),
      loadingComponent: loader,
    }),
    Footer: lazyLoadComponent({
      componentLoader: () => import('../components/Footer/index.vue'),
      loadingComponent: loader,
    }),
  },
  data() {
    return {
      openTab: 1,
    };
  },
  methods: {
    toggleTabs(tabNumber) {
      this.openTab = tabNumber;
    },
  },
};
</script>
<style scoped>
.about-sub-nav .tab-nav > li {
  height: 60px !important;
  line-height: 60px !important;
  font-family: "Nunito", sans-serif;
  font-size: 15px !important;
  color: #18181a !important;
  font-weight: bold;
}
h3 {
  font-family: "museo_sans_rounded900";
  font-size: 20px;
  margin-bottom: 0;
  color: #465a05;
  height: 43px;
  line-height: 43px;
}
</style>
